import React, { Dispatch, SetStateAction } from 'react';
import { Map, ViewStateChangeEvent } from 'react-map-gl';
import StopMarkers from './StopMarker';
import VehicleMarkers from './VehicleMarkers';
import { ACCESS_TOKEN } from '../constants';
import { Route, RouteStop, Viewport } from '../type';
import { getBoundsOfStops } from '../mapUtils';
import { WebMercatorViewport } from '@math.gl/web-mercator';

const RouteMap = (props: RouteMapProps) => {
  const { mapStyle, mayId, route, setSelectedStop, selectedStop } = props;
  const [viewport, setViewport] = React.useState(() => {
    const rawBounds = getBoundsOfStops(route.stops).toArray() as [
      [number, number],
      [number, number]
    ];
    return new WebMercatorViewport({
      width: (window as Window).innerWidth,
      height: (window as Window).innerHeight * 0.8
    }).fitBounds(rawBounds, {
      padding: Math.min(36, (window as Window).innerWidth / 2)
    }) as Viewport;
  });

  return (
    <Map
      {...viewport}
      mapboxAccessToken={ACCESS_TOKEN}
      mapStyle={mapStyle}
      onMove={(v: ViewStateChangeEvent) => setViewport(v.viewState)}
      style={{ position: 'absolute', width: '100vw', height: '80vh' }}
    >
      <VehicleMarkers {...{ mayId }} />
      <StopMarkers {...{ route, setSelectedStop, selectedStop, viewport }} />
    </Map>
  );
};

export interface RouteMapProps {
  mayId: string;
  route: Route;
  mapStyle: string;
  selectedStop?: RouteStop;
  setSelectedStop: Dispatch<SetStateAction<RouteStop | undefined>>;
}

export default RouteMap;

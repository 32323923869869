import React, { useEffect, useState } from 'react';
import RouteMap, { RouteMapProps } from './components/RouteMap';
import routes from './routes.json';
import './styles.css';
import { DispatchMode, Route, RouteStop } from './type';
import BottomSheet from './components/BottomSheet';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';

const getVehicleRoute = (routeName: string, stopIds?: string[]): Route => {
  function isRoute(route: Route) {
    return route.name === routeName;
  }
  const route = routes.find(isRoute);
  if (route !== undefined) {
    if (stopIds && stopIds.length > 0) {
      const filteredStops = route.stops.filter((stop) =>
        stopIds.includes(stop.stop_point_id)
      );
      return {
        ...route,
        stops: filteredStops
      };
    }
    return route;
  } else {
    throw Error(`Invalid route name, ${routeName}, check routes.json`);
  }
};

export interface MapProps {
  mayId: string;
  routeName: string;
  dispatchMode: DispatchMode;
  stopIds: string[];
}

const Map = (props: MapProps) => {
  const history = useHistory();
  const { mayId, routeName, dispatchMode, stopIds } = props;
  const [routeMapProps, setRouteMapProps] = useState<RouteMapProps | undefined>(
    undefined
  );
  const [selectedStop, setSelectedStop] = useState<RouteStop | undefined>(
    undefined
  );

  useEffect(() => {
    if (!mayId || !routeName) {
      history.replace('/');
    }
  }, [mayId, routeName]);

  useEffect(() => {
    if (!routeMapProps) {
      const route = getVehicleRoute(routeName, stopIds);

      setRouteMapProps({
        mayId,
        route,
        mapStyle: 'mapbox://styles/mapbox/streets-v12',
        setSelectedStop,
        selectedStop
      });
    }
  }, [routeMapProps]);

  useEffect(() => {
    if (routeMapProps === undefined) return;
    setRouteMapProps({
      mayId: routeMapProps.mayId,
      route: routeMapProps.route,
      mapStyle: routeMapProps.mapStyle,
      setSelectedStop,
      selectedStop
    });
  }, [selectedStop]);

  return (
    <div>
      {routeMapProps ? (
        <>
          <RouteMap {...routeMapProps} />
          <BottomSheet
            {...{ mayId: routeMapProps.mayId, selectedStop, dispatchMode }}
          />
        </>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </div>
  );
};

export default Map;

import { isNonProdShift } from './common/utils';
import { OBSOLETE_DATA_TIMEOUT_SEC } from './constants';
import { VehicleStateObject } from './type';

const dateTimeIsObsolete = (lastUpdateTime: number): boolean => {
  const updateTimeDate = new Date(lastUpdateTime);
  const now = new Date();
  const diffInSeconds = Math.abs(
    (now.getTime() - updateTimeDate.getTime()) / 1000
  );
  return diffInSeconds > OBSOLETE_DATA_TIMEOUT_SEC;
};

export const getValidVehicles = (
  vehicles: VehicleStateObject[]
): VehicleStateObject[] =>
  vehicles.filter(
    (vehicle) =>
      parseFloat(vehicle.current.location.lon) !== 0 &&
      parseFloat(vehicle.current.location.lat) !== 0 &&
      !dateTimeIsObsolete(vehicle.current.timestamp) &&
      isNonProdShift(vehicle.current.driverId)
  );

import React, { useEffect, useState } from 'react';
import { Marker, MarkerProps } from 'react-map-gl';
import useInterval from '../hooks/useInterval';
import { bearing, distance } from '../mapUtils';
import { setVehicleUpdates } from '../redux/actions';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getVehicles } from '../requests';
import { Platform, Vehicle, VehicleStateObject } from '../type';
import AvSiennaIcon from './AvSiennaIcon';
import { Auth } from 'aws-amplify';
import { getValidVehicles } from '../vehicleUtils';

const VehicleMarker = (props: VehicleMarkerProps) => {
  const { prevVehicle, vehicle } = props;
  const [heading, setHeading] = useState(0.0);

  const markerProps: MarkerProps = {
    longitude: parseFloat(vehicle.location.lon),
    latitude: parseFloat(vehicle.location.lat)
  };

  useEffect(() => {
    const startLat = parseFloat(prevVehicle.location.lat);
    const startLon = parseFloat(prevVehicle.location.lon);
    const destLat = parseFloat(vehicle.location.lat);
    const destLon = parseFloat(vehicle.location.lon);
    const d = distance(startLat, startLon, destLat, destLon);
    console.log('Vehicle moved ' + d + ' meters');
    if (d > 10) {
      setHeading(bearing(startLat, startLon, destLat, destLon));
    }
  }, [prevVehicle, vehicle]);

  return (
    <Marker {...markerProps} style={{ maxWidth: '28px', height: 'auto' }}>
      <AvSiennaIcon
        sx={{
          width: '100%',
          height: 'auto',
          transform: `rotate(${heading.toFixed(0)}deg)`
        }}
      />
    </Marker>
  );
};

interface VehicleMarkerProps {
  // Previous data point but same vehicle
  prevVehicle: Vehicle;
  vehicle: Vehicle;
  platform: Platform | undefined;
}

const VehicleMarkers = (props: VehicleMarkersProps) => {
  const { mayId } = props;
  const dispatch = useAppDispatch();
  const filteredVehicles: VehicleStateObject[] = useAppSelector((state) => {
    const vehicle = state.vehicles[mayId];
    if (vehicle === undefined) return [];
    return getValidVehicles([vehicle]);
  });

  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const session = await Auth.currentSession();
        setAccessToken(session.getAccessToken().getJwtToken());
      } catch (error) {
        console.error('Failed to get access token', error);
      }
    };

    getAccessToken();
  }, []);

  const pollVehicleUpdates = async () => {
    if (accessToken) {
      const vehicles = await getVehicles(accessToken);
      dispatch(setVehicleUpdates(vehicles));
    }
  };

  useInterval(pollVehicleUpdates, 1000);

  return (
    <>
      {filteredVehicles.map((v) => {
        return (
          <VehicleMarker
            key={v.current.vehicleId}
            prevVehicle={v.previous}
            vehicle={v.current}
            platform='sienna-v1'
          />
        );
      })}
    </>
  );
};

interface VehicleMarkersProps {
  mayId: string;
}

export default VehicleMarkers;

export type DispatchRequest = {
  vehicle_id: string;
  stop_location: ViaStyleLocation;
  stop_point_id: string;
  task_type?: string;
};

export type ClearDispatchRequest = {
  vehicle_id: string;
  stop_list_id: string;
};

export interface ILocation {
  lat: string;
  lon: string;
  ele: string | undefined;
}

export type Route = {
  name: string;
  stops: RouteStop[];
};

export type RouteStop = {
  location: ViaStyleLocation;
  anchor?: string;
  stop_point_id: string;
};

export type StopList = {
  stops: RouteStop[];
  stop_list_id: string;
};

export interface ViaStyleLocation {
  lat: string;
  lng: string; // Via uses 'lng' where we usually use 'lon'
}

export enum VehicleState {
  available,
  dispatched,
  enroute
}

export enum DispatchMode {
  demo,
  riderExperience
}

export enum TaskType {
  DROPOFF = 'DROPOFF',
  PICKUP = 'PICKUP'
}

export type Vehicle = {
  location: ILocation;
  timestamp: number;
  driverId: string;
  vehicleId: string;
  route: string;
  state: VehicleState;
  stopList: StopList;
};

export type VehicleStateObject = {
  current: Vehicle;
  previous: Vehicle;
};

export type State = {
  [mayId: string]: VehicleStateObject;
};

export type VehicleUpdateAction = {
  type: string;
  vehicles: Vehicle[];
};

export interface Viewport {
  latitude: number;
  longitude: number;
  zoom: number;
}

export type Platform = 'gem-v3' | 'gem-v2' | 'rx450h-v1' | 'sienna-v1';

export interface MayVehicle {
  may_id: string;
  platform: Platform;
}

const getApiUrl = (): string => {
  if (process.env.NODE_ENV === 'production') {
    return '/v1';
  } else {
    return 'http://127.0.0.1:3000/v1';
  }
};

export const API_URL = getApiUrl();
export const ACCESS_TOKEN =
  'pk.eyJ1Ijoic2RpLW1hcGJveCIsImEiOiJjbGwxNjhqY28wZW1zM3JvNnFiejFwbm53In0.UXTUrwC0Y0NAZ-jyxrFaLg';

export const OBSOLETE_DATA_TIMEOUT_SEC = 30; // How many seconds until realtime data is considered obsolete

export const GLOBAL_CONFIG_URL =
  'https://global-config.maymobility.com/global_config.json';

export const MAY_MOBILITY_COLORS = {
  BLACK: '#1F1F1F',
  GREEN: '#369040',
  LIGHT_BLUE: '#00BCEE',
  LIGHT_GREEN: '#98C949',
  ORANGE: '#FF9C17',
  WHITE: '#FFFFFF'
};

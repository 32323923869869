import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material';

function AvSiennaIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox='0 0 20 40'>
      <g clipPath='url(#AV_Sienna_svg__clip0_426:3166)'>
        <g filter='url(#AV_Sienna_svg__filter0_d_426:3166)'>
          <path
            d='M10.264 36.898c2.532 0 4.112-.325 4.931-.575.211-.065.31-.134.43-.166 1.057-.304.883-1.595.92-2.296.355-6.758.354-14.091.113-21.656-.053-1.518 0-2.824-.05-4.537-.026-.362-.184-.67-.203-1.013l-.041-.694a1.929 1.929 0 00-.223-.8c-.173-.33-.3-.624-.557-.9-.832-.845-1.94-1.637-3.15-1.72-.787-.077-1.536-.193-2.327-.179h-.18c-.812-.003-1.533.125-2.341.214-1.216.102-2.222.823-3.05 1.682a4.321 4.321 0 00-.622.93c-.129.25-.2.523-.212.802l-.03.694c0 .362-.166.666-.189 1.017-.086 1.533.053 3.015.023 4.537 0 7.939.156 14.887.48 21.695.048.698-.039 1.942 1.033 2.228.12.032.223.1.434.162 1.627.392 3.153.648 4.83.59'
            fill='#1F1F1F'
            fillOpacity={0.15}
            shapeRendering='crispEdges'
          />
        </g>
        <path
          d='M10.27 36.618c2.44 0 3.963-.324 4.752-.573.204-.064.298-.133.415-.165 1.018-.303.916-1.537.952-2.236.342-6.728.364-14.068.131-21.6-.05-1.512.058-2.991-.047-4.518-.025-.36-.178-.666-.196-1.008l-.04-.69a1.967 1.967 0 00-.215-.797 4.153 4.153 0 00-.625-.907 4.685 4.685 0 00-3.059-1.44 19.468 19.468 0 00-2.283-.093h-.044a20.07 20.07 0 00-2.345.13 4.745 4.745 0 00-3.062 1.486c-.24.282-.44.593-.6.925-.124.25-.193.522-.204.8l-.029.69c0 .36-.16.663-.182 1.012-.083 1.527.051 3.003.022 4.518-.134 7.52 0 14.861.462 21.6.047.695-.036 1.933.996 2.218.117.032.215.1.419.162 1.568.39 3.184.559 4.8.5'
          fill='#fff'
        />
        <path
          d='M15.433 35.873c-.16.076-.325.14-.495.19-.785.25-1.883.591-4.323.61a19.512 19.512 0 01-5.157-.58 1.342 1.342 0 01-.418-.162l.484-.904a.12.12 0 01.145-.061 22.19 22.19 0 004.964.677 12.964 12.964 0 004.218-.789.129.129 0 01.16.065c.153.32.265.655.422.954z'
          fill='#000'
        />
        <path
          d='M2.244 13.347l1.09-.313a.332.332 0 00.237-.303l.106-.032v-.543h-.099a.06.06 0 00-.048-.062.062.062 0 00-.028 0c-.39.08-.755.255-1.058.511a2.394 2.394 0 00-.255.256.29.29 0 00-.062.14v.253a.093.093 0 00.035.075.096.096 0 00.082.018zM17.891 13.377l-1.09-.296a.324.324 0 01-.24-.298l-.113-.03-.03-.546h.117a.061.061 0 01.048-.062.064.064 0 01.028 0c.391.074.758.244 1.066.494.092.077.178.161.258.252a.29.29 0 01.062.14c.01.084.017.168.018.252a.089.089 0 01-.035.081.091.091 0 01-.089.013z'
          fill='#1A1A1A'
        />
        <path
          opacity={0.82}
          d='M10.051 7.782c2.626 0 4.938.692 5.582 1.44.524.58.087 4.421-.28 5.76a.366.366 0 01-.353.292 37.455 37.455 0 00-4.894-.295 38.76 38.76 0 00-4.953.36.388.388 0 01-.243-.087.4.4 0 01-.117-.197c-.385-1.332-.818-5.17-.302-5.76.633-.757 2.935-1.47 5.56-1.52'
          fill='#000'
        />
        <path
          d='M4.095 12.772s.41 2.34.414 2.808l.026 3.675.054 1.984v1.67c0 .774.051 2.1.084 3.37 0 .36 0 .89.033 1.523.029.99.113 2.26.138 3.06.03.853.113 1.76.113 1.76a.691.691 0 010 .234c-.063.26-.18.506-.342.72 0 0-.236.313-.295.295a5.952 5.952 0 01-.225-1.14c-.062-.426-.204-1.369-.24-2.067L3.68 26.56l-.058-1.836-.069-3.039-.022-3.153v-3.989l.022-1.548c.003-.156.016-.31.04-.464l.12-.699c.036-.212.131-.543.131-.543s.098-.134.116 0c.019.133.026.72.026.72l.109.763zM16.022 12.692s-.363 2.347-.363 2.811l.029 3.68-.026 1.983v1.67c0 .774-.022 2.1-.033 3.37v1.523c0 .99-.076 2.264-.09 3.063-.019.854-.084 1.764-.084 1.764a.665.665 0 000 .23c.07.263.194.508.364.72 0 0 .243.31.301.289.108-.373.176-.755.204-1.141.058-.429.185-1.376.21-2.07.026-.695.114-4.108.114-4.108l.029-1.836.025-3.042-.029-3.154-.036-2.34-.037-1.645-.043-1.548a3.35 3.35 0 00-.047-.464l-.128-.695c-.044-.212-.142-.54-.142-.54s-.098-.137-.116 0c-.018.137 0 .72 0 .72l-.102.76z'
          fill='#000'
        />
        <path
          opacity={0.82}
          d='M6.931 3.466a.04.04 0 00-.003-.045.04.04 0 00-.044-.013 8.662 8.662 0 00-.837.324 4.59 4.59 0 00-.923.49c-.199.12-.373.275-.517.457a1.518 1.518 0 00-.232.547 4.996 4.996 0 00-.146.688 4.49 4.49 0 00-.109.954c.04.04.364-.407.411-.461l.335-.335a6.35 6.35 0 01.436-.324.362.362 0 00.109-.115c.35-.63.789-1.208 1.302-1.717l.218-.45zM12.96 3.423a.043.043 0 01.026-.061.044.044 0 01.025 0c.168.046.597.208.837.313.33.12.643.28.934.475.2.118.378.27.524.45.115.164.196.349.24.544.07.225.122.454.156.687.075.312.117.63.124.95-.04.04-.364-.403-.418-.453l-.339-.331c-.076-.076-.443-.317-.443-.317a.362.362 0 01-.11-.115 7.98 7.98 0 00-.486-.72 7.828 7.828 0 00-.84-.961l-.23-.461z'
          fill='#000'
        />
        <path
          d='M6.818 3.934s.557-.18 1.051-.303c.495-.122 1.24-.234 1.24-.234a.718.718 0 01.095-.162.724.724 0 01.105-.104c-.43.042-.856.112-1.276.209-.567.133-.946.262-.946.262-.057.046-.11.098-.156.155a.867.867 0 00-.113.177zM13.095 3.887s-.557-.173-1.055-.285c-.498-.111-1.244-.216-1.244-.216a.72.72 0 00-.094-.162.949.949 0 00-.11-.104c.431.036.859.1 1.28.19.568.123.946.25.946.25a.903.903 0 01.276.328z'
          fill='#998675'
        />
        <path
          d='M7.098 3.487c.2-.073.406-.133.615-.18.342-.086.814-.205 1.09-.244a7.25 7.25 0 012.316.018c.346.064.565.108 1.056.223l.49.111-.33-.248a7.933 7.933 0 00-.884-.234 6.341 6.341 0 00-.927-.094c-.237 0-.895-.021-1.244 0-.35.022-.833.072-1.09.126-.213.039-.42.095-.623.17l-.145.057-.324.295z'
          fill='#998675'
        />
        <path
          d='M10.389 3.089c0-.082-.201-.146-.448-.144-.247.001-.447.07-.447.15.001.082.202.147.449.145.247-.002.447-.07.446-.151z'
          fill='#3A4351'
        />
        <path
          d='M4.266 34.034h-.142a.054.054 0 00-.055.058c.002.202.016.404.044.605.028.211.087.417.175.612l.098.165c.058.087.127.167.203.238.084.076.181.135.288.173 0 0-.091-.08-.204-.227a1.549 1.549 0 01-.247-.508 2.415 2.415 0 01-.098-.662v-.4a.057.057 0 00-.02-.04.058.058 0 00-.042-.014zM16.19 33.932h.141a.058.058 0 01.058.058c0 .202-.012.404-.036.604-.024.213-.08.42-.167.616l-.095.166c-.056.088-.123.17-.2.24a.905.905 0 01-.284.18s.091-.078.2-.233c.054-.074.1-.152.139-.234.04-.089.074-.18.101-.274.06-.215.09-.438.088-.662v-.4a.057.057 0 01.032-.055.058.058 0 01.022-.006z'
          fill='#F15A24'
        />
        <path
          d='M4.567 19.302a5.112 5.112 0 01-.654-.458 1.935 1.935 0 01-.364-.493v1.037s.026.108.364.27c.207.098.422.177.644.237l.01-.593zM15.673 19.493c.231-.139.451-.295.658-.468.144-.144.267-.308.364-.486v.749c.006.088.006.177 0 .266 0 0-.03.119-.364.285-.21.103-.429.186-.654.248l-.004-.594zM4.731 27.643c-.157.031-.316.05-.476.058-.134.016-.27.016-.404 0-.054 0-.105-.065-.098-.083l.026.547.301-.029c.07-.007.317-.036.364-.043.047-.007.302-.032.302-.032l-.015-.418zM15.64 27.812c.163.043.33.073.498.09a1.4 1.4 0 00.364 0c.054 0 .105-.061.102-.079l-.019.536s-.232-.032-.301-.04c-.07-.006-.317-.046-.364-.053l-.302-.047.022-.407z'
          fill='#fff'
        />
        <path
          d='M9.815 21.898v-.36h.49v.36a.248.248 0 01-.247.245.249.249 0 01-.172-.073.243.243 0 01-.071-.172zM5.305 15.556l.301.202-.277.405-.3-.201a.245.245 0 01-.067-.34.247.247 0 01.343-.066zM15.086 15.918l-.3.203-.277-.401.3-.203a.25.25 0 01.344.064.243.243 0 01-.065.34l-.002-.003z'
          fill='#565655'
        />
        <path
          opacity={0.3}
          d='M10.178 21.836l-.661-2.491H9.16l.782 2.49h.236z'
          fill='#1E1E1E'
        />
        <path
          d='M10.179 21.836l.065-2.491h-.356l.054 2.49h.237z'
          fill='#1E1E1E'
        />
        <path
          opacity={0.3}
          d='M14.833 15.652l-2.862 2.293.204.288 2.796-2.39-.138-.19z'
          fill='#1E1E1E'
        />
        <path
          d='M14.833 15.652l-2.862 1.933.204.288 2.796-2.03-.138-.191z'
          fill='#1E1E1E'
        />
        <path
          opacity={0.3}
          d='M5.288 15.691l2.829 2.989-.208.284-2.76-3.082.139-.19z'
          fill='#1E1E1E'
        />
        <path
          d='M5.288 15.69l2.829 1.908-.208.285-2.76-2.002.139-.19z'
          fill='#1E1E1E'
        />
        <path
          opacity={0.3}
          d='M12.31 17.732v1.602a.358.358 0 01-.107.254.366.366 0 01-.257.106l-4.142.36a.366.366 0 01-.257-.106.358.358 0 01-.107-.254v-1.242c0-1.228 1.368-2.945 2.611-2.945a2.267 2.267 0 011.597.648 2.224 2.224 0 01.662 1.577z'
          fill='#383838'
        />
        <path
          d='M7.815 18.992V17.75a2.206 2.206 0 01.658-1.573 2.249 2.249 0 011.59-.651 2.259 2.259 0 011.585.651c.42.417.657.981.658 1.57v1.245a.358.358 0 01-.107.255.365.365 0 01-.257.105H8.175a.365.365 0 01-.255-.106.358.358 0 01-.105-.253z'
          fill='#383838'
        />
        <path
          d='M10.677 16.825c-.368-.364-1.022-.307-1.461.127-.439.435-.496 1.082-.129 1.446.368.364 1.022.308 1.46-.127.44-.434.497-1.082.13-1.446z'
          fill='#161616'
        />
        <path
          d='M10.917 17.433a.85.85 0 00-.855-.846.85.85 0 00-.854.846.85.85 0 00.854.846.85.85 0 00.855-.846z'
          fill='#fff'
        />
        <path
          d='M4.666 15.237l.032 1.357c0 .17-.069.306-.182.335l-.447.119c-.032 0-.069-.029-.072-.08-.04-.454.005-.912.134-1.35.038-.122.085-.242.138-.36a.2.2 0 01.098-.097c.064-.026.13-.046.197-.06.047 0 .098.064.102.136zM15.477 15.237l-.037 1.357c0 .17.07.306.182.335l.451.119c.033 0 .066-.029.07-.08a3.645 3.645 0 00-.135-1.35 2.138 2.138 0 00-.138-.36.192.192 0 00-.095-.097 1.245 1.245 0 00-.2-.06c-.047 0-.098.064-.098.136z'
          fill='#565655'
        />
      </g>
      <defs>
        <clipPath id='AV_Sienna_svg__clip0_426:3166'>
          <path fill='#fff' transform='rotate(-90 20 20)' d='M0 0h40v20H0z' />
        </clipPath>
        <filter
          id='AV_Sienna_svg__filter0_d_426:3166'
          x={0.455}
          y={0.361}
          width={19.373}
          height={40.56}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy={1} />
          <feGaussianBlur stdDeviation={1.5} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0' />
          <feBlend
            in2='BackgroundImageFix'
            result='effect1_dropShadow_426:3166'
          />
          <feBlend
            in='SourceGraphic'
            in2='effect1_dropShadow_426:3166'
            result='shape'
          />
        </filter>
      </defs>
    </SvgIcon>
  );
}

export default AvSiennaIcon;

import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { useAppSelector } from '../redux/hooks';
import { clearDispatchVehicle, dispatchVehicle } from '../requests';
import {
  DispatchMode,
  RouteStop,
  TaskType,
  VehicleState,
  VehicleStateObject
} from '../type';
import { Auth } from 'aws-amplify';
import { getValidVehicles } from '../vehicleUtils';

interface BottomSheetProps {
  mayId: string;
  selectedStop?: RouteStop;
  dispatchMode: DispatchMode;
}

enum BottomSheetButtonType {
  GO_HERE,
  PICKUP,
  DROPOFF
}

const BottomSheet = (props: BottomSheetProps) => {
  const { mayId, selectedStop, dispatchMode } = props;
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const session = await Auth.currentSession();
        setAccessToken(session.getAccessToken().getJwtToken());
      } catch (error) {
        console.log('Error fetching access token', error);
      }
    };
    fetchAccessToken();
  }, []);

  const vehicle: VehicleStateObject | undefined = useAppSelector((state) => {
    const vehicle = state.vehicles[mayId];
    if (vehicle === undefined) return undefined;
    const vehicles = getValidVehicles([vehicle]);
    if (vehicles.length > 0) {
      return vehicles[0];
    }
    return undefined;
  });

  const getContent = () => {
    if (vehicle === undefined) {
      const name = mayId[0].toUpperCase() + mayId.slice(1);
      return `Unable to find vehicle with name ${name}`;
    } else if (vehicle.current.state === VehicleState.enroute) {
      return `We are on the way to ${vehicle.current.stopList.stops[0].stop_point_id}`;
    } else if (vehicle.current.state === VehicleState.dispatched) {
      return `Dispatched to ${vehicle.current.stopList.stops[0].stop_point_id} but not yet enroute`;
    } else if (selectedStop === undefined) {
      return "Tap the stop you'd like to visit next";
    } else {
      return `Selected destination: ${selectedStop?.stop_point_id}`;
    }
  };

  const onDispatchClick = async () => {
    if (selectedStop === undefined || vehicle === undefined) return;
    console.log('Dispatching...');
    const res = await dispatchVehicle(
      vehicle.current,
      selectedStop,
      TaskType.DROPOFF,
      accessToken
    );
    if (res.status === 200) {
      console.log('Dispatched vehicle...');
    } else {
      console.error(res.data);
      alert(JSON.stringify(res.data, null, 2));
    }
  };

  const onPickUpClick = async () => {
    if (selectedStop === undefined || vehicle === undefined) return;
    console.log('Dispatching pick-up...');
    const res = await dispatchVehicle(
      vehicle.current,
      selectedStop,
      TaskType.PICKUP,
      accessToken
    );
    if (res.status === 200) {
      console.log('Dispatched vehicle...');
    } else {
      console.error(res.data);
      alert(JSON.stringify(res.data, null, 2));
    }
  };

  const onDropOffClick = async () => {
    if (selectedStop === undefined || vehicle === undefined) return;
    console.log('Dispatching drop-off...');
    const res = await dispatchVehicle(
      vehicle.current,
      selectedStop,
      TaskType.DROPOFF,
      accessToken
    );
    if (res.status === 200) {
      console.log('Dispatched vehicle...');
    } else {
      console.error(res.data);
      alert(JSON.stringify(res.data, null, 2));
    }
  };

  const onCancelClick = async () => {
    if (vehicle === undefined) return;
    console.log('Cancelling dispatch...');
    const res = await clearDispatchVehicle(vehicle.current, accessToken);
    if (res.status === 200) {
      console.log('Cancelled dispatched vehicle...');
    } else {
      console.error(res.data);
      alert(JSON.stringify(res.data, null, 2));
    }
  };

  const getOnClick = (buttonType: BottomSheetButtonType) => {
    switch (buttonType) {
      case BottomSheetButtonType.DROPOFF:
        return onDropOffClick;
      case BottomSheetButtonType.PICKUP:
        return onPickUpClick;
      case BottomSheetButtonType.GO_HERE:
        return onDispatchClick;
      default:
        return onDispatchClick;
    }
  };

  const getButton = (
    expectedMode: DispatchMode,
    text: string,
    buttonType: BottomSheetButtonType
  ) => {
    if (
      vehicle === undefined ||
      vehicle.current.state !== VehicleState.available ||
      dispatchMode != expectedMode
    )
      return;
    return (
      <Button
        variant='contained'
        disabled={selectedStop === undefined || vehicle === undefined}
        size='large'
        onClick={() => {
          if (selectedStop !== undefined) getOnClick(buttonType)();
        }}
      >
        {text}
      </Button>
    );
  };

  const getDemoButton = () => {
    return getButton(
      DispatchMode.demo,
      'Go Here',
      BottomSheetButtonType.GO_HERE
    );
  };

  const getTestPickupButton = () => {
    return getButton(
      DispatchMode.riderExperience,
      'Pick-Up',
      BottomSheetButtonType.PICKUP
    );
  };

  const getTestDropoffButton = () => {
    return getButton(
      DispatchMode.riderExperience,
      'Drop-Off',
      BottomSheetButtonType.DROPOFF
    );
  };

  const getCancelButton = () => {
    if (
      vehicle === undefined ||
      vehicle.current.state === VehicleState.available
    )
      return;
    return (
      <Button
        variant='contained'
        disabled={selectedStop === undefined || vehicle === undefined}
        size='large'
        onClick={onCancelClick}
        color='warning'
      >
        Cancel
      </Button>
    );
  };

  return (
    <Paper
      sx={{
        width: 'calc(var(--vw, 1vw) * 100)',
        height: '20vh',
        margin: '0 auto',
        position: 'absolute',
        zIndex: 2,
        bottom: 0,
        display: 'flex',
        padding: '1rem',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        boxSizing: 'border-box'
      }}
      elevation={2}
    >
      <Typography>{getContent()}</Typography>
      {getDemoButton()}
      {getTestPickupButton()}
      {getTestDropoffButton()}
      {getCancelButton()}
    </Paper>
  );
};

export default BottomSheet;

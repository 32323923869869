import { LngLatBounds } from 'mapbox-gl';
import { RouteStop } from './type';

export const getBoundsOfStops = (stops: RouteStop[]): LngLatBounds => {
  const bounds = new LngLatBounds();
  for (const stop of stops) {
    bounds.extend({
      lat: parseFloat(stop.location.lat),
      lon: parseFloat(stop.location.lng)
    });
  }
  return bounds;
};

// Converts from degrees to radians.
const toRadians = (degrees: number): number => {
  return (degrees * Math.PI) / 180;
};

// Converts from radians to degrees.
const toDegrees = (radians: number): number => {
  return (radians * 180) / Math.PI;
};

export const bearing = (
  startLat: number,
  startLon: number,
  destLat: number,
  destLon: number
): number => {
  const startLati = toRadians(startLat);
  const startLng = toRadians(startLon);
  const destLati = toRadians(destLat);
  const destLng = toRadians(destLon);

  const y = Math.sin(destLng - startLng) * Math.cos(destLati);
  const x =
    Math.cos(startLati) * Math.sin(destLati) -
    Math.sin(startLati) * Math.cos(destLati) * Math.cos(destLng - startLng);
  const brng = Math.atan2(y, x);
  return (toDegrees(brng) + 360) % 360;
};

export const distance = (
  startLat: number,
  startLon: number,
  destLat: number,
  destLon: number
): number => {
  const R = 6371e3; // meters
  const dLat = toRadians(destLat - startLat);
  const dLon = toRadians(destLon - startLon);
  const lat1 = toRadians(startLat);
  const lat2 = toRadians(destLat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

import React, { Dispatch, SetStateAction } from 'react';
import { Anchor, Marker, Popup } from 'react-map-gl';
import { ReactComponent as StopMarkerIcon } from '../img/place_black_36dp.svg';
import { Route, RouteStop, Viewport } from '../type';

const ZOOM_LEVEL_TO_SHOW_MARKERS = 14.5;

const StopMarkers = (props: StopMarkersProps) => {
  const { route, selectedStop, setSelectedStop } = props;

  return (
    <>
      {route.stops.map((stop, index) => (
        <StopMarker
          key={index}
          {...{
            stop,
            setSelectedStop: setSelectedStop,
            selected: stop === selectedStop,
            viewport: props.viewport
          }}
        />
      ))}
    </>
  );
};

interface StopMarkersProps {
  route: Route;
  selectedStop?: RouteStop;
  setSelectedStop: Dispatch<SetStateAction<RouteStop | undefined>>;
  viewport: Viewport;
}

const StopMarker = (props: StopMarkerProps) => {
  const { stop, selected, setSelectedStop, viewport } = props;
  const [anchorPosition, setAnchorPosition] = React.useState<Anchor>('bottom');

  React.useEffect(() => {
    setAnchorPosition(stop.anchor as Anchor);
  }, []);

  return (
    <div
      onClick={() => {
        setSelectedStop(stop);
      }}
    >
      <Popup
        latitude={parseFloat(stop.location.lat)}
        longitude={parseFloat(stop.location.lng)}
        closeButton={false}
        anchor={anchorPosition}
        closeOnClick={false}
        style={{
          filter: 'drop-shadow(1px 1px 1px #000)',
          fontWeight: selected ? 600 : 500,
          zIndex: 1,
          top: -16,
          display:
            viewport.zoom < ZOOM_LEVEL_TO_SHOW_MARKERS && !selected
              ? 'none'
              : ''
        }}
        className={selected ? 'selected-popup' : ''}
      >
        <div>{stop.stop_point_id}</div>
      </Popup>
      <Marker
        latitude={parseFloat(stop.location.lat)}
        longitude={parseFloat(stop.location.lng)}
        style={{
          width: selected ? '54px' : '48px',
          height: 'auto'
        }}
      >
        <StopMarkerIcon
          fill='#1F1F1F'
          style={{ width: '100%', height: 'auto' }}
        />
      </Marker>
    </div>
  );
};

interface StopMarkerProps {
  stop: RouteStop;
  setSelectedStop: Dispatch<SetStateAction<RouteStop | undefined>>;
  selected: boolean;
  viewport: Viewport;
}

export default StopMarkers;

// Keep in sync with packages/server/src/common/utils.ts until repo is
// restructured to have a single copy of the common files

const nonProdDriverIds = [
  /^t-.*$/,
  /^tester$/,
  /^auto-tester$/,
  /^fe-tester$/,
  /^fe-auto-tester$/,
  /^demo$/,
  /^demo-tester$/,
  /^gold-standard$/,
  /^trainee$/,
  /^data-collection$/,
  /^scout$/,
  /^soak$/,
  /^rider-only-tester$/,
  /^demo-rider-only$/
];

export const isNonProdShift = (driverId: string): boolean =>
  nonProdDriverIds.some((testId) => testId.test(driverId));

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  Typography
} from '@mui/material';
import routes from './routes.json';
import { setVehicleUpdates } from './redux/actions';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { getVehicles } from './requests';
import { DispatchMode, Vehicle } from './type';
import MayLogo from './img/may_logo_green.png';
import { getValidVehicles } from './vehicleUtils';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { MapProps } from './Map';

const getVehicleRoutes = (): string[] => routes.map((route) => route.name);
const ROUTE_NAMES = getVehicleRoutes();

interface HomeProps {
  setMapProps: Dispatch<SetStateAction<MapProps | undefined>>;
}

const Home: React.FC<HomeProps> = ({ setMapProps }) => {
  const history = useHistory();
  const [mayId, setMayId] = useState('');
  const [routeName, setRouteName] = useState(ROUTE_NAMES[0]);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [features, setFeatures] = useState<string[]>([]);
  const [stopIds, setStopIds] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const availableMayIds = useAppSelector((state) => {
    const filtered = getValidVehicles(Object.values(state.vehicles));
    return filtered.map((vehicle) => vehicle.current.vehicleId);
  });

  useEffect(() => {
    let isMounted = true; // Track if the component is mounted

    const fetchData = async () => {
      try {
        const session = await Auth.currentSession();
        if (!isMounted) return;

        const token = session.getAccessToken().getJwtToken();
        setAccessToken(token);

        const sessionFeatures = session.getAccessToken().payload.features || [];
        setFeatures(sessionFeatures);

        const route = session.getAccessToken().payload.route;
        if (route && !route.includes('all')) {
          setRouteName(route);
        }

        const mayIdForExternalUser = session.getAccessToken().payload.vehicle;
        if (mayIdForExternalUser && !mayIdForExternalUser.includes('all')) {
          setMayId(mayIdForExternalUser);
        }

        const stopIdsString = session.getAccessToken().payload.stops;
        setStopIds(
          stopIdsString && !stopIdsString.includes('all')
            ? stopIdsString.split(',')
            : []
        );
        if (sessionFeatures.includes('demo') && !mayIdForExternalUser) {
          console.error('No vehicle assigned for external user.');
        }

        if (sessionFeatures.includes('demo')) {
          setMapProps({
            mayId: mayIdForExternalUser,
            routeName,
            dispatchMode: DispatchMode.demo,
            stopIds
          });
          history.push('/demo');
        } else if (sessionFeatures.includes('rx')) {
          setMapProps({
            mayId: mayIdForExternalUser,
            routeName,
            dispatchMode: DispatchMode.riderExperience,
            stopIds
          });
          history.push('/rx');
        } else {
          history.push('/');
        }

        if (
          token &&
          !sessionFeatures.includes('demo') &&
          !sessionFeatures.includes('rx')
        ) {
          const vehicles = await getVehicles(token);
          if (isMounted) {
            dispatch(setVehicleUpdates(vehicles));
          }
        }
      } catch (error) {
        console.error('Error getting access token', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup function sets isMounted to false
    };
  }, [history, setMapProps, mayId, routeName, dispatch]);

  return (
    <Box
      sx={{
        width: 'calc(var(--vw, 1vw) * 100)',
        margin: 'auto',
        display: 'flex',
        padding: '1rem',
        flexDirection: 'column',
        justifyContent: 'center',
        rowGap: '12px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img src={MayLogo} width='240px' alt='May Logo' />
      </Box>
      <FormControl fullWidth>
        <InputLabel variant='standard' htmlFor='controlled-native-route-select'>
          Route
        </InputLabel>
        <NativeSelect
          value={routeName}
          inputProps={{
            name: 'route',
            id: 'controlled-native-route-select'
          }}
          onChange={(e) => setRouteName(e.target.value)}
        >
          {ROUTE_NAMES.sort((a, b) =>
            a.toLowerCase().localeCompare(b.toLowerCase())
          ).map((name) => (
            <option value={name} key={name}>
              {name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel variant='standard' htmlFor='controlled-native-id-select'>
          May ID
        </InputLabel>
        <NativeSelect
          value={mayId}
          inputProps={{
            name: 'mayId',
            id: 'controlled-native-id-select'
          }}
          onChange={(e) => setMayId(e.target.value)}
        >
          <option value={''} />
          {availableMayIds.map((mayId) => (
            <option value={mayId} key={mayId}>
              {mayId}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <Button
        variant='contained'
        disabled={mayId === ''}
        size='large'
        onClick={() => {
          if (mayId !== '') {
            setMapProps({
              mayId,
              routeName,
              dispatchMode: DispatchMode.demo,
              stopIds
            });
            history.push('/demo');
          }
        }}
      >
        Demo Mode
      </Button>
      <Button
        variant='contained'
        disabled={mayId === ''}
        size='large'
        onClick={() => {
          if (mayId !== '') {
            setMapProps({
              mayId,
              routeName,
              dispatchMode: DispatchMode.riderExperience,
              stopIds
            });
            history.push('/rx');
          }
        }}
      >
        Rx Mode
      </Button>
      <Box sx={{ display: 'inline', float: 'left' }}>
        <Typography variant='h4'>Notes for use:</Typography>
        <ul>
          <li>The vehicle must be on the allow list to work with this tool.</li>
          <li>
            The vehicle must be running a non-production shift to appear in the
            dropdown.
          </li>
          <li>
            Select the route and vehicle, then press `Demo Mode` (Pickup
            commands only) or `Rx Mode` (Pickup and drop-off commands).
          </li>
        </ul>
        <Typography>
          More information can be found{' '}
          <a
            href='https://maymobility.atlassian.net/wiki/spaces/RE/pages/2698575873/User+Doc+Internal+May+ODS+Dispatcher+-+For+Demos+Testing'
            target='_blank'
            rel='noopener noreferrer'
          >
            here
          </a>
          .
        </Typography>
      </Box>
    </Box>
  );
};

export default Home;

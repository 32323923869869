const USER_POOL_ID =
  process.env.ODS_DISPATCHER_USER_POOL_ID || 'us-east-1_hE29SXQUv';
const REGION = process.env.ODS_DISPATCHER_REGION || 'us-east-1';
const CLIENT_ID =
  process.env.ODS_DISPATCHER_CLIENT_ID || '28kmlfo8bjnl62223u1ge5eqt9';
const DOMAIN =
  process.env.ODS_DISPATCHER_COGNITO_DOMAIN ||
  'fleet-api-maymobility.auth.us-east-1.amazoncognito.com';
const SIGNIN_REDIRECT =
  process.env.ODS_DISPATCHER_REDIRECT_SIGN_IN ||
  'https://ods.sdi.maymobility.com';
const SIGNOUT_REDIRECT =
  process.env.ODS_DISPATCHER_REDIRECT_SIGN_OUT ||
  'https://ods.sdi.maymobility.com';

console.log(process.env.ODS_DISPATCHER_REDIRECT_SIGN_IN);

// Amazon Cognito Configuration
export default {
  app: {
    basename: '/'
  },
  cognito: {
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: CLIENT_ID,
    domain: DOMAIN,
    redirectSignIn: SIGNIN_REDIRECT,
    redirectSignOut: SIGNOUT_REDIRECT
  }
};

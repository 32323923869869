import axios, { AxiosResponse } from 'axios';
import { API_URL } from './constants';
import {
  ClearDispatchRequest,
  DispatchRequest,
  RouteStop,
  TaskType,
  Vehicle
} from './type';

export const getVehicles = async (token?: string): Promise<Vehicle[]> => {
  const res = await axios({
    method: 'GET',
    url: API_URL + '/vehicle/list',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    responseType: 'json'
  });
  return res.data;
};

export const dispatchVehicle = async (
  vehicle: Vehicle,
  stop: RouteStop,
  taskType: TaskType,
  token?: string
): Promise<AxiosResponse> => {
  const body: DispatchRequest = {
    stop_point_id: stop.stop_point_id,
    stop_location: stop.location,
    vehicle_id: vehicle.vehicleId,
    task_type: taskType.toString()
  };
  try {
    return await axios({
      method: 'POST',
      url: API_URL + '/vehicle/dispatch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      data: body
    });
  } catch (e: any) {
    return e.response;
  }
};

export const clearDispatchVehicle = async (
  vehicle: Vehicle,
  token?: string
): Promise<AxiosResponse> => {
  const body: ClearDispatchRequest = {
    stop_list_id: vehicle.stopList.stop_list_id,
    vehicle_id: vehicle.vehicleId
  };
  try {
    return await axios({
      method: 'DELETE',
      url: API_URL + '/vehicle/dispatch',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      responseType: 'json',
      data: body
    });
  } catch (e: any) {
    return e.response;
  }
};
